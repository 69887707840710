<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded }">
        <div id="sticky-heading-container" class="heading-container with-subtitle sticky" v-if="type && destination_user_id">
            <div class="back-title-container">
                <BackButton />
                <h1 v-if="lead && lead.name" class="title">{{state === 'interested' ? lead.name : 'Marc-Antoine Dion'}}</h1>
                <h1 v-else class="title">{{$t(`messages.title`)}}</h1>
            </div>
        </div>

        <div id="with-sticky-header" :class="['with-sticky-header row', type, state]" v-show="type && destination_user_id">
            <div id="chat-box" class="left chat-box">
                <div class="card chat">
                    <div class="card-heading">
                        <p class="title">{{ $t('messages.chat.title') }}</p>
                    </div>
                    <div :class="['card-body', { 'with-error': converse_error }]">
                        <span v-if="loaded && messages && messages.length === 0" class="empty">{{ $t(`messages.chat.empty.${type}`) }}</span>
                        <div ref="messages-container" class="messages-container">
                            <div :id="`message-${index}`" v-for="(message, index) in messages" :class="['message', type === message.sender_type ? { 'right': true } : {'left': true }]" :key="message._id">
                                <span class="payload">{{ message.payload }}</span>
                                <span class="time" v-if="(index + 1)  === messages.length">{{ formate_time(message.createdAt, type === message.sender_type) }}</span>
                            </div>
                        </div>
                    </div> 

                    <div class="card-footer">
                        <input v-model="message" class="input input-text" type="text" @keyup.enter="send_message()">
                        <button :class="['cta cta-primary messaging', { loading }]" @click="send_message()"><font-awesome-icon :icon="['fa', 'paper-plane']" /></button>
                    </div>

                    <p v-if="converse_error" class="title with-error-icon error"><font-awesome-icon :icon="['fa', 'circle-exclamation']" />{{ $t('messages.chat.send_messages_error') }}</p>
                </div>

                <div v-if="type === 'work_provider' && state === 'interested'" class="card notice">
                    <p>La messagerie deviendra disponible lorsque le candidat sera débloqué</p>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../../components/Layout.vue';
import BackButton from '../../../components/Navigation/BackButton.vue';
import { bus } from '../../../main';

export default {
    name: 'Message',

    components: {
        Layout,
        BackButton,
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        },

        destination_user_id: {
            type: String,
            default: ''
        },
    },

    watch: {
        container_margin: function() {
            const self = this;
            self.set_dynamic_height();
        }
    },

    data() {
        return {
            routeName: 'message',
            classes: { dashboard: true, message: true },

            type: '',
            loaded: false,
            lead: null,

            state: 'interested',

            message: '',
            messages: null,
            loading: false,

            converse_error: false,
            socket_connection_message_shown: false,
        }
    },

    computed: {
        ...mapGetters([
            'is_person',
            'token',
            'current_user_id',
            'container_margin'
        ]),

        is_overflowing: function() {
            const self = this;

            const ref = self.$refs['messages-container'];

            if(ref) {
                const height = ref.clientHeight('messages-container');
                const number_of_messages = self.messages.length;

                console.log(height);
                console.log(number_of_messages);
            }

            return false;
        }
    },

    methods: {
        get_limited_work_provider_info: function() {
            const self = this;

            self.$axios
                .get(`messaging/work-provider/${self.destination_user_id}`)
                .then(response => {
                    self.loaded = true;
                    if(response.status == 200) {
                        self.lead = response.data;

                        self.$nextTick(() => {
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }

                    self.loaded = true;
                });
        },

        send_message: function() {
            const self = this;

            self.converse_error = false;
            
            if(self.message.trim().length === 0) { return; }

            const data = {
                sender_id: self.current_user_id,
                destination_id: self.destination_user_id,
                sender_type: self.type,
                destination_type: self.type === 'person' ? 'work_provider' : 'person',
                payload: self.message.trim(),
                createdAt: new Date(),
                token: self.token
            }

            if(!self.$socket.connected) { 
                self.$toasted.error(
                        self.$t('notifications.send_message_error'),
                        { icon: 'circle-exclamation' }
                    );
            }
            
            self.$socket.emit('send_message', data, function(confirmation) {
                if(confirmation) { 
                    self.message = '';
                }else {
                    self.$toasted.error(
                        self.$t('notifications.send_message_error'),
                        { icon: 'circle-exclamation' }
                    );
                    
                    console.log(`MESSAGE: error in send_message: ${JSON.stringify(data, null, 4)}`)
                }
            });
        },

        formate_time: function(date, sender) {
            const self = this;
            
            const now = new Date();
            const minutes = self.$moment(now).diff(self.$moment(date), 'minutes');
            const hours = self.$moment(now).diff(self.$moment(date), 'hours');
            const days = self.$moment(now).diff(self.$moment(date), 'days');
            
            if(minutes === 0) { return self.$t('language.now') }
            else if (minutes === 1) { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${minutes} ${self.$t('language.minute')}` }
            else if (minutes < 60)  { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${minutes} ${self.$t('language.minutes')}` }
            else if (hours === 1)  { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${hours} ${self.$t('language.hour')}` }
            else if (hours > 1)  { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${hours} ${self.$t('language.hours')}` }
            else if (days === 1)  { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${days} ${self.$t('language.day')}` }
            else  { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${days} ${self.$t('language.days')}` }
        },

        send_user_info: function() {
            const self = this;
            if(self.$socket.connected) {
                self.$socket.emit('user_info_received', { 
                    user_id: self.current_user_id, 
                    buddy_id: self.destination_user_id, 
                    token: self.token 
                }, function(confirmation) {
                    if(!confirmation) { console.log('an error occured sending user info') }
                });
            }else {
                self.$toasted.error(
                    self.$t('notifications.get_messages_error'),
                    { icon: 'circle-exclamation' }
                );
                
                console.log(`MESSAGE: error in send_user_info`)
            }
        },

        set_dynamic_height: function() {
            const self = this;

            if(self.container_margin) {
                const chat_element = document.getElementById('chat-box');
                if(chat_element) {
                    chat_element.style.height = `calc(100%)`;
                }
            }
        }
    },

    sockets: {
        connect: function () { },
        connect_error: function () {
            const self = this; 

            console.log('socket connection error');

            if(!self.socket_connection_message_shown) {
                self.$toasted.error(
                    self.$t('notifications.get_messages_error'),
                    { icon: 'circle-exclamation' }
                );
                
                console.log(`MESSAGE: eror in connect_error`)


                self.socket_connection_message_shown = true;
            }
        },
        connect_fail: function () { 
            const self = this;

            console.log('socket connection failed');

            if(!self.socket_connection_message_shown) {
                self.$toasted.error(
                    self.$t('notifications.get_messages_error'),
                    { icon: 'circle-exclamation' }
                );

                console.log(`MESSAGE: error in connect_fail`)

                self.socket_connection_message_shown = true;
            }
        },
        
        ask_for_user_info: function () {
            const self = this;
            self.send_user_info();
        },

        get_messages: function (data) {
            const self = this;
            const response = data;

            if(response.status === 200) {
                self.messages = response.data;
            }else {
                self.$toasted.error(
                    self.$t('notifications.get_messages_error'),
                    { icon: 'circle-exclamation' }
                );
                
                console.log(`MESSAGE: error in get_messages: ${JSON.stringify(data, null, 4)}`)
            }
        }
    },

    mounted() {
        const self = this;
        self.type = 'person';
        
        self.get_limited_work_provider_info();

        const res = self.$socket.open();
        if(res.connected) {
            self.$nextTick(() => {
                self.send_user_info();
            })
        }

        self.$nextTick(() => {
            self.set_dynamic_height();
        })

    },

    unmounted() {
        self.$socket.close();
    },

    deactivated() {
        self.$socket.close();
    }
}
</script>