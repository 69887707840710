<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded }">
        <div id="sticky-heading-container" class="heading-container with-subtitle sticky" v-if="type && destination_user_id">
            <div class="back-title-container">
                <BackButton />
                <!-- <h1 v-if="lead && lead.name" class="title">{{ lead.name === 'anonymous' ? $t('messages.info.work_provider.header.anonymous') : lead.name }}</h1> -->
                <h1 v-if="lead && lead.name" class="title">{{ lead.name === 'anonymous' ? 'Marc-Antoine Dion' : lead.name }}</h1>
                <h1 v-else class="title">{{$t(`messages.title`)}}</h1>
            </div>
            
            <div class="cta-container" v-if="lead && lead.stage === 'interested'">
                <button :class="['cta cta-primary cta-slim', { loading: loading_unlock }]" @click="update_stage('unlocked')">{{ $t('messages.info.work_provider.header.unlock') }}</button>
                <button class="cta cta-outline dark cta-slim" @click="update_stage('notUnlocked')">{{ $t('messages.info.work_provider.header.not_unlock') }}</button>
            </div>
        </div>

        <div v-if="lead" id="with-sticky-header" :class="['with-sticky-header row', type, lead.stage]" v-show="type && destination_user_id">
            <div id="chat-box" :class="['left chat-box']">
                <div class="card chat">
                    <div class="card-heading">
                        <p class="title">{{ $t('messages.chat.title') }}</p>
                    </div>
                    <div :class="['card-body', { 'with-error': converse_error }]">
                        <span v-if="loaded && messages && messages.length === 0" class="empty">{{ $t(`messages.chat.empty.${type}`) }}</span>
                        <div ref="messages-container" class="messages-container">
                            <div :id="`message-${index}`" v-for="(message, index) in messages" :class="['message', type === message.sender_type ? { 'right': true } : {'left': true }]" :key="message._id">
                                <span class="payload">{{ message.payload }}</span>
                                <span class="time" v-if="(index + 1)  === messages.length">{{ format_time(message.createdAt, type === message.sender_type) }}</span>
                            </div>
                        </div>
                    </div> 
                    
                    <div class="card-footer">
                        <input v-model="message" class="input input-text" type="text" @keyup.enter="send_message()">
                        <button :class="['cta cta-primary messaging', { loading }]" @click="send_message()"><font-awesome-icon :icon="['fa', 'paper-plane']" /></button>
                    </div>

                    <p v-if="converse_error" class="title with-error-icon error"><font-awesome-icon :icon="['fa', 'circle-exclamation']" />{{ $t('messages.chat.send_messages_error') }}</p>
                </div>

                <div v-if="lead && lead.stage === 'interested'" class="card notice">
                    <p>{{ $t('messages.chat.work_provider.locked') }}</p>
                </div>
            </div>

            <div v-if="type === 'work_provider' && lead" class="right info">
                <div class="card card-messaging-info">
                    <div class="card-heading">
                        <p class="title">{{ $t('messages.info.work_provider.title') }}</p>
                        <p class="interested_since">{{ $t('candidates.card.interested_since') }} <span class="bold">{{ lead.history.interested | moment("DD/MM/YYYY, H:mm") }}</span></p>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <span>{{ $t('candidates.card.salary') }}</span>
                            <span class="bubble">{{ lead.salary.type === 'hourly' ? `${lead.salary.amount}$ / h` : `$${lead.salary.amount}` }}</span>
                        </div>

                        <span class="divider"></span>
                        
                        <div :class="['multi-offer-container']">
                            <p class="title">{{ $t('messages.info.work_provider.card.locations.title') }}</p>
                            <div class="row">
                                <div class="card" v-for="match in lead.match_results" :key="match._id">
                                    <div class="card-heading"><p>{{ match.offer_name }}</p></div>
                                    <div class="card-body">
                                        <div class="locations-container">
                                            <div class="row locations">
                                                <span v-for="location in match.locations" :key="location._id" :class="['bubble success']">{{ location.name }}</span>
                                            </div>

                                            <div class="row distance">
                                                <p v-if="match.locations.length === 1" v-html="$t('messages.info.work_provider.card.locations.distance.single', { distance: match.locations[0].distance })"></p>
                                                <p v-else v-html="$t('messages.info.work_provider.card.locations.distance.multiple', { small: match.closest_distance, big: match.furthest_distance })"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <span class="divider"></span>

                        <div class="row nmb">
                            <span v-if="lead.availableNow" v-html="$t('candidates.card.availability.now')"></span>
                            <span v-else v-html="$t('candidates.card.availability.future', { date: $moment(lead.nextTimeAvailable).format('DD/MM/YYYY') })"></span>

                            <span class="bubble" v-if="!lead.availableUntil">{{ $t('candidates.card.availability.availableUntil.none') }}</span>
                            <span v-else v-html="$t('candidates.card.availability.availableUntil.set', { date: $moment(lead.availableUntil).format('DD/MM/YYYY') })"></span>
                        </div>

                        <div class="row work_days_row">
                            <div class="work_days_container">
                                <span :class="['bubble', lead.workDays.includes(workDay) ? 'active': '']" v-for="workDay in workDays" :key="workDay">{{$t(workDay)}}</span>
                            </div>

                            <span class="bold" v-html="$t('messages.info.work_provider.card.hours_per_week', { hours: lead.hoursPerWeek })"></span>
                        </div>
                        
                        <div class="row work_times_row">
                            <div>
                                <WorkTimesComponent  v-if="lead.workTimes"
                                                    :workDays="lead.workDays"
                                                    :workTimes="lead.workTimes"
                                                    :header="false"
                                                    :readonly="true"
                                                    :force="true"
                                                    :in_match_detail="true" />
                            </div>
                        </div>

                        <span class="divider"></span>

                        <div class="row interests_row"> 
                            <span>{{ $t('messages.info.work_provider.card.interests.title') }}</span>
                            <div class="interests">
                                <div class="interests_container" v-if="lead.classification.all">
                                    <span :class="['bubble']">{{ $t('messages.info.work_provider.card.interests.all') }}</span>
                                </div>

                                <div class="interests_container" v-else>
                                    <span v-for="i in lead.classification.interests" :key="i._id" :class="['bubble']">{{ $t(`jobs.${i.key}`) }}</span>
                                </div>
                            </div>
                        </div>
                        
                        <span class="divider"></span>

                        <div class="row benefits_row"> 
                            <span>{{ $t('messages.info.work_provider.card.benefits.title') }}</span>
                            <div class="benefits">
                                <div class="benefits_container" v-if="lead.benefits.choices.length === 0 && lead.benefits.other === ''">
                                    <span :class="['bubble']">{{ $t('messages.info.work_provider.card.benefits.none') }}</span>
                                </div>

                                <div class="benefits_container" v-else>
                                    <span v-for="choice in lead.benefits.choices" :key="choice" :class="['bubble']">{{ $t(`benefits.long.${choice}`) }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="row benefits_other_row" v-if="lead.benefits.other !== ''">
                            <p>{{ $t('messages.info.work_provider.card.benefits.other') }}</p>
                            <span>{{ lead.benefits.other }}</span>
                        </div>

                        <!-- <span v-if="lead.stage === 'unlocked'" class="divider"></span>

                        <div v-if="lead.stage === 'unlocked'" class="row assign">
                            <span>Assigné à</span>  

                            <multiselect    v-model="primary_contact" 
                                            :options="primary_contact_choices"
                                            :close-on-select="true"
                                            :multiple="false"
                                            :searchable="false"
                                            :placeholder="$t('workProviderOffer.shared.card.primary-contact.title')"

                                            :select-label="$t('language.multiselect.label.select')"
                                            :selected-label="$t('language.multiselect.label.selected')"
                                            :deselect-label="$t('language.multiselect.label.deselect')"> 
                            </multiselect>  
                        </div> -->
                    </div>
                </div>

                <div class="card card-messaging-contact-info">
                    <div class="card-heading">
                        <p class="title">{{ $t('messages.info.contact.title') }}</p>
                    </div>

                    <div class="card-body">
                        <p v-if="lead.stage === 'interested'" class="empty">{{ $t('messages.info.contact.card.empty') }}</p>
                        <div class="contact-info" v-else>
                            <div v-if="lead.contact_info.email.value" class="row">
                                <span>{{ $t('messages.info.contact.card.contact_info.email') }}</span>
                                <span>{{ lead.contact_info.email.value }}</span>
                            </div>

                            <div v-if="lead.contact_info.phone.value" class="row bottom">
                                <span>{{ $t('messages.info.contact.card.contact_info.phone') }}</span>

                                <div class="column">
                                    <span>{{ $utils._format_phone_number(lead.contact_info.phone.value) }}</span>
                                    <span class="small" v-if="lead.contact_info.phone.supports_texts">{{ $t('messages.info.contact.card.contact_info.supports_texts') }}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../../components/Layout.vue';
import BackButton from '../../../components/Navigation/BackButton.vue';
import { bus } from '../../../main';
import WorkTimesComponent from '../../../components/Form/WorkTimesComponent.vue';

export default {
    name: 'WorkProviderMessage',

    components: {
        Layout,
        BackButton,
        WorkTimesComponent
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        },

        destination_user_id: {
            type: String,
            default: ''
        },
    },

    watch: {
        container_margin: function() {
            const self = this;
            self.set_dynamic_height();
        }
    },

    data() {
        return {
            routeName: 'message',
            classes: { dashboard: true, message: true },

            type: '',
            loaded: false,
            lead: null,

            message: '',
            messages: null,
            loading: false,

            converse_error: false,
            socket_connection_message_shown: false,

            workDays: [
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday'
            ],

            primary_contact: null,
            primary_contact_choices: ['Clara Martin', 'Gabrielle Martin', 'Guillaume Roy'],

            loading_unlock: false
        }
    },

    computed: {
        ...mapGetters([
            'is_person',
            'token',
            'current_user_id',
            'container_margin'
        ]),

        is_overflowing: function() {
            const self = this;

            const ref = self.$refs['messages-container'];

            if(ref) {
                const height = ref.clientHeight('messages-container');
                const number_of_messages = self.messages.length;

                console.log(height);
                console.log(number_of_messages);
            }

            return false;
        }
    },

    methods: {
        get_limited_worker_info: function() {
            const self = this;

            self.$axios
                .get(`messaging/worker/${self.destination_user_id}`)
                .then(response => {
                    self.loaded = true;
                    if(response.status == 200) {
                        self.lead = response.data;
                        console.log(self.lead);

                        self.$nextTick(() => {
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }

                    self.loaded = true;
                });
        },

        send_message: function() {
            const self = this;

            self.converse_error = false;
            
            if(self.message.trim().length === 0) { return; }

            const data = {
                sender_id: self.current_user_id,
                destination_id: self.destination_user_id,
                sender_type: self.type,
                destination_type: self.type === 'person' ? 'work_provider' : 'person',
                payload: self.message.trim(),
                createdAt: new Date(),
                token: self.token
            }

            if(!self.$socket.connected) { 
                self.$toasted.error(
                        self.$t('notifications.send_message_error'),
                        { icon: 'circle-exclamation' }
                    );
            }
            
            self.$socket.emit('send_message', data, function(confirmation) {
                if(confirmation) { 
                    self.message = '';
                }else {
                    self.$toasted.error(
                        self.$t('notifications.send_message_error'),
                        { icon: 'circle-exclamation' }
                    );
                    
                    console.log(`MESSAGE: error in send_message: ${JSON.stringify(data, null, 4)}`)
                }
            });
        },

        format_time: function(date, sender) {
            const self = this;

            const now = new Date();
            const minutes = self.$moment(now).diff(self.$moment(date), 'minutes');
            const hours = self.$moment(now).diff(self.$moment(date), 'hours');
            const days = self.$moment(now).diff(self.$moment(date), 'days');
            
            if(minutes === 0) { return self.$t('language.now') }
            else if (minutes === 1) { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${minutes} ${self.$t('language.minute')}` }
            else if (minutes < 60)  { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${minutes} ${self.$t('language.minutes')}` }
            else if (hours === 1)  { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${hours} ${self.$t('language.hour')}` }
            else if (hours > 1)  { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${hours} ${self.$t('language.hours')}` }
            else if (days === 1)  { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${days} ${self.$t('language.day')}` }
            else  { return `${self.$t(`messages.chat.${sender ? 'sent' : 'received'}`)} ${days} ${self.$t('language.days')}` }
        },

        send_user_info: function() {
            const self = this;
            if(self.$socket.connected) {
                self.$socket.emit('user_info_received', { 
                    user_id: self.current_user_id, 
                    buddy_id: self.destination_user_id, 
                    token: self.token 
                }, function(confirmation) {
                    if(!confirmation) { console.log('an error occured sending user info') }
                });
            }else {
                self.$toasted.error(
                    self.$t('notifications.get_messages_error'),
                    { icon: 'circle-exclamation' }
                );
                
                console.log(`MESSAGE: error in send_user_info`)
            }
        },

        set_dynamic_height: function() {
            const self = this;

            if(self.container_margin) {
                const chat_element = document.getElementById('chat-box');
                if(chat_element) {
                    chat_element.style.height = `calc(100%)`;
                }
            }
        },

        update_stage: function(stage) {
            const self = this;

            if(stage === 'unlocked') {
                self.loading_unlock = true;

                const data = {
                    stage,
                    selectedMatches: self.lead.worker_match_ids
                }

                self.$axios
                    .post(`matches/worker/stage`, data)
                    .then(response => {
                        if(response.status == 200) {
                            self.loading_unlock = false;
                            self.$nextTick(() => {
                                self.get_limited_worker_info();
                            })
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            }
        }
    },

    sockets: {
        connect: function () { },
        connect_error: function () {
            const self = this; 

            console.log('socket connection error');

            if(!self.socket_connection_message_shown) {
                self.$toasted.error(
                    self.$t('notifications.get_messages_error'),
                    { icon: 'circle-exclamation' }
                );
                
                console.log(`MESSAGE: eror in connect_error`)


                self.socket_connection_message_shown = true;
            }
        },
        connect_fail: function () { 
            const self = this;

            console.log('socket connection failed');

            if(!self.socket_connection_message_shown) {
                self.$toasted.error(
                    self.$t('notifications.get_messages_error'),
                    { icon: 'circle-exclamation' }
                );

                console.log(`MESSAGE: error in connect_fail`)

                self.socket_connection_message_shown = true;
            }
        },
        
        ask_for_user_info: function () {
            const self = this;
            self.send_user_info();
        },

        get_messages: function (data) {
            const self = this;
            const response = data;

            if(response.status === 200) {
                self.messages = response.data;
            }else {
                self.$toasted.error(
                    self.$t('notifications.get_messages_error'),
                    { icon: 'circle-exclamation' }
                );
                
                console.log(`MESSAGE: error in get_messages: ${JSON.stringify(data, null, 4)}`)
            }
        }
    },

    mounted() {
        const self = this;
        self.type = 'work_provider';
        self.get_limited_worker_info();

        const res = self.$socket.open();
        if(res.connected) {
            self.$nextTick(() => {
                self.send_user_info();
            })
        }

        self.$nextTick(() => {
            self.set_dynamic_height();
        })

    },

    unmounted() {
        self.$socket.close();
    },

    deactivated() {
        self.$socket.close();
    }
}
</script>