<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded }">
        <div v-if="type">
            <div id="sticky-heading-container" class="heading-container sticky with-subtitle">
                <h1 class="title">{{$t(`messages.title`)}}</h1>
                <p class="subtitle">{{$t(`messages.subtitle.${type}`)}}</p>
            </div>

            <div v-if="$screen.width > 768" id="with-sticky-header" class="table-container with-sticky-header">
                <table :class="['table-messaging nomargin-bottom hover', { 'with-bubble': number_of_unread_messages !== 0 }]">
                    <tr>
                        <th v-if="type === 'person'">{{$t(`messages.table.th.employer`)}}</th>
                        <th v-if="type === 'work_provider'">{{$t(`messages.table.th.candidate`)}}</th>
                        <th>{{$t(`messages.table.th.offers`)}}</th>
                    </tr>
                    <tr v-for="(user, index) in users" :key="index" @click="open_messaging(user.user_id)">
                        <td :class="['bold', { 'with-bubble': messages_not_seen_by_sender[user.user_id] }]"><span v-if="messages_not_seen_by_sender[user.user_id]" class="message-bubble">{{ messages_not_seen_by_sender[user.user_id] }}</span>{{ user.person }}</td>
                        <td>{{ format_offers(user.offers) }}</td>
                    </tr>
                    <tr class="no-hover">
                        <td v-if="!loaded" class="loading" colspan="2"></td>
                        <td v-if="loaded && users.length === 0" class="bold" colspan="2">{{$t('messages.empty')}}</td>
                    </tr>
                </table>
                
                <div class="table-disclaimer-container">
                    <p class="table-disclaimer" v-html="$t(`messages.table.disclaimer.${type}`)"></p>
                </div>
            </div>

            <div v-else id="with-sticky-header" class="messages-cards with-sticky-header">
                <div v-if="loaded">
                    <div v-for="(user, index) in users" :key="index" @click="open_messaging(user.user_id)" class="card card-messaging">
                        <div class="header-container">
                            <div class="left">
                                <p class="title">{{ user.person }}</p>
                            </div>
                            <span v-if="messages_not_seen_by_sender[user.user_id]" class="message-bubble">{{ messages_not_seen_by_sender[user.user_id] }}</span>
                        </div>

                        <div class="offers">
                            <p class="title">{{ $t('messages.cards.offers') }}</p>

                            <div class="offers-container">
                                <p class="offer" v-for="(offer, index) in user.offers" :key="index">- {{ format_offer(offer) }}</p>
                            </div>
                        </div>
                    </div>

                    <div v-if="users.length === 0">
                        <div class="card empty-card">
                            <p class="title">{{ $t(`messages.empty`) }}</p>
                        </div>
                    </div>
                </div>
                
                <div v-else class="card loading"></div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '../../../components/Layout.vue';

import { bus } from '../../../main';

export default {
    name: 'Messages',

    components: {
        Layout
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'messages',
            classes: { dashboard: true, messages: true },

            type: '',
            loaded: false,
            users: []
        }
    },

    computed: {
        ...mapGetters([
            'is_person',
            'messages_not_seen_by_sender',
            'number_of_unread_messages'
        ])
    },

    methods: {
        open_messaging: function(destination_user_id) {
            const self = this;
            self.$utils._navigate_to_name_with_params(`message`, { destination_user_id })
        },

        get_users_to_chat: function() {
            const self = this;

            self.$axios
                .get(`messaging/${self.type}/users`)
                .then(response => {
                    if(response.status == 200) {
                        self.users = response.data;
                        self.loaded = true;

                        self.$nextTick(() => {
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                    
                    self.loaded = true;
                });
        },

        format_offers: function(offers) {
            const self = this;
            let string = '';

            for (let index = 0; index < offers.length; index++) {
                const offer = offers[index];

                if(index === offers.length - 1) { string = string + self.format_offer(offer, false)
                } else { string = string + self.format_offer(offer, true) }
            }

            return string;
        },

        format_offer: function(offer, delim = false) {
            return delim ? `${offer._id.toString().substring(offer._id.toString().length - 4, offer._id.toString().length).toUpperCase()} - ${offer.name}, ` : `${offer._id.toString().substring(offer._id.toString().length - 4, offer._id.toString().length).toUpperCase()} - ${offer.name}`;
        }
    },

    mounted() {
        const self = this; 
        self.type = self.is_person ? 'person' : 'work_provider';

        self.get_users_to_chat();
    }
}
</script>